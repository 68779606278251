// 腾讯云对象存储
import COS from 'cos-js-sdk-v5'
import request from '@/api/request'
import { Message } from 'element-ui'
import { Loading } from 'element-ui';
import Vue from "vue";
const vm = new Vue()
var config = {
  Bucket: '',
  Region: '',
};
export default function upload(file, callback) {
  let loadingInstance = Loading.service({ fullscreen: true });
  request.get('tob/sys/cos/getTmpCosKey', {}).then(res => { // 后台接口返回 密钥相关信息
    if (res.data.code == 200) {
      const resData = res.data.data
      const credentials = resData.credentials;
      config.Bucket = resData.bucket;
      config.Region = resData.region;
      var cos = new COS({
        getAuthorization: function (options, callback) {
          callback({
            TmpSecretId: credentials.tmpSecretId,
            TmpSecretKey: credentials.tmpSecretKey,
            XCosSecurityToken: credentials.sessionToken,
            ExpiredTime: resData.expiredTime,
          })
        }
      })
      let fName = file.name
      let filename = fName.substr(fName.lastIndexOf('/') + 1);
      cos.putObject({// 此处用了最简单的上传函数，此外，腾讯云还支持分片上传函数等等。
          Bucket: config.Bucket, // 存储桶名称
          Region: config.Region, // 地区
          Key: 'image/tenant/icon/'+filename+ '?'+ Math.random(), // 图片名称
          StorageClass: 'STANDARD',
          Body: file, // 上传文件对象
          onHashProgress: function (progressData) {
            // console.log('校验中', JSON.stringify(progressData));
          },
          onProgress: function (progressData) {
            // console.log('上传中', JSON.stringify(progressData));
        },
      },
      function (err, data) {
        if (err) {
          Message({
            message: '文件上传失败,请重新上传',
            type: 'error',
          })
          loadingInstance.close();
        } else {
          let fileUrl = data.Location
          callback(fileUrl)
          loadingInstance.close();
        }
      }
    )
   }else{
    vm.$toast(res.data.msg, "error", 2000);
    loadingInstance.close();
   }
  })
}
