<template>
  <b-card title="系统配置">
  
    <!-- 表单 -->
    <b-form>
      <b-form-group
        id="input-group-1"
        label="系统ICON:"
        label-for="input-1"
        description="为空时将显示系统默认头像哦！"
      >
      <b-row>
        <el-upload
          class="avatar-uploader"
          action=""
          :auto-upload="false"
          :show-file-list="false"
          :on-change="handleImgChange"
          :on-remove="handleImgRemove"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload">
          <img v-if="tenantForm.sysIconUrl" :src="tenantForm.sysIconUrl" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </b-row>
      <b-row v-if="$checkButtonPermission('208001001')">
        <b-button size="sm" @click="handleImgRemove">删除图片</b-button>
        <b-button size="sm" class="margin-left-10px" @click="savePicture" variant="success">保存图片</b-button> 
      </b-row>
      </b-form-group>

      <b-form-group
        id="input-group-1"
        label="系统名称:"
        label-for="input-1"
        description="为空时将显示系统默认名称哦！"
      >
        <b-form-input
          id="input-1"
          v-model="tenantForm.sysName"
          type="text"
          placeholder="请输入系统名称"
          required
        ></b-form-input>
      </b-form-group>
      <b-button v-if="$checkButtonPermission('208001002')" @click="saveForm"  variant="primary">保存</b-button>
    </b-form>
  </b-card>
</template>

<script>
import uploadCos from '../../libs/uploadCos.js'
import {
  BAvatar,BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BButton
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import request from '@/api/request'

export default {
  components: {
    BCard,
    BAvatar,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BForm
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      dir: false,
      imgFile: null,
      tenantForm:{
        id: null,
        sysName: "",
        sysIconUrl: ""
      },
      imageUrl: '',

    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getTenantConfig()
  },
  methods: {
    getTenantConfig(){
      request.get('tob/tenant/tenantSysConfig/query').then(res => {
        console.log('RES',res)
        if (res.data.success) {
          this.tenantForm = {
            sysName: res.data.data.sysName,
            sysIconUrl: res.data.data.sysIconUrl,
            id: res.data.data.id
          }
        }
      })
    },
    handleImgChange(file) {
      this.tenantForm.sysIconUrl = URL.createObjectURL(file.raw);
      //文件暂存，方便上传
      this.imgFile = file;
    },
    //编辑后修改的图片删除怒
    handleImgRemove() {
        this.tenantForm.sysIconUrl = "";
        this.imgFile = null;
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    saveForm(){
      if (this.tenantForm.sysName.length > 10) {
        this.$bvToast.toast("系统名称长度不能超过10个字符",{
          title: `提示`,
          variant: 'danger',
          solid: true
        })
        return
      }
      request.post('tob/tenant/tenantSysConfig/saveBasisInfo',this.tenantForm).then(res => {
        console.log('RES',res)
        if (res.data.success) {
          this.$bvToast.toast("操作成功",{
            title: `提示`,
            variant: 'success',
            solid: true
          })
        }else{
          this.$bvToast.toast(res.data.msg,{
            title: `提示`,
            variant: 'danger',
            solid: true
          })
        }
      })
    },
    savePicture(){
      let that = this
      if(this.imgFile && this.imgFile.name.length > 256){
        this.$bvToast.toast("图片名称长度超过256个字符,无法上传",{
          title: `提示`,
          variant: 'danger',
          solid: true
        })
        return
      }
      this.$confirm(
        '此操作将上传文件, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async ()=>{
          console.log('savePicture')
          await that.uploadFiles()
        })
    },

    //上传文件
    async uploadFiles() {
      var imgExist = this.isFileExist(this.imgFile)
      if (!imgExist) {
          return true
      }
      let flag = true
      if (imgExist) {
        let that = this
        await uploadCos(this.imgFile.raw, res =>{
          if (res) {
            that.messageUploadFileSuccess(that.imgFile)
            that.imgFile = null
            that.tenantForm.sysIconUrl = "https://" + res
          } else {//失败退出
            that.messageUploadFileFail(that.imgFile)
            flag = false
          }
        }) //调用引用的init-cos的上传方法
      }
      return flag
    },
    
    //文件上传成功消息
    messageUploadFileSuccess(file) {
        this.$message({
            message: '文件上传成功：' + file.raw.name,
            type: 'success'
        });
    },
    //是否选择了文件
    isFileExist(file) {
      return file != null & file != undefined & JSON.stringify(file) != "{}"
    },
    messageUploadFileFail(file) {
      this.$message.error('文件上传失败：' + file.raw.name + "；重新确定试试")
    },
  },
}
</script>

<style lang="scss">
  .margin-left-10px{
    margin-left: 10px;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
